import React from "react"
import { graphql } from "gatsby"

import Layout from "../page/Layout"
import SEO from "../page/Seo"
import Sections from "../page/Sections"
import { PopupsProvider } from "../../contexts/PopupsContext"
import { normalize, normalizeSections } from "../../utils/utils"

const Page = ({ data, masterPageURL }) => {
  const normalizedData = normalize(data)

  const site = normalizedData.strapiSite
  const page = normalizedData.strapiPage

  page.sections = normalizeSections(page.sections)

  // change first section to h1, and the rest to h2 but keep client style choice
  if (page.sections) {
    page.sections.map((section, index) => {
      const textObjName = section.text ? "text" : "sectionTitle"
      const textObject = section[`${textObjName}`]
      if (index === 0 && textObject) {
        textObject.as = "h1"
        section[`${textObjName}`] = textObject
      } else if (index !== 0 && textObject) {
        textObject.as = "h2"
        section[`${textObjName}`] = textObject
      }
      return section
    })
  }

  console.log("page.sections:", page.sections)
  const firstSectionHasParallax =
    page?.sections &&
    page?.sections[0]?.section?.parallaxOptions !== "noParallax" &&
    page?.sections[0]?.section?.parallaxOptions !== undefined

  const htmlSnippets = page?.htmlSnippets

  let pageSpecificNavTheme =
    page?.hideHeader !== "yes"
      ? {
          overrideSiteStyles: page?.overrideSiteStyles,
          transparentNav: page?.transparentNav,
          pageNavTheme: page?.pageNavTheme,
          bgNav: page?.bgNav,
        }
      : {}

  const isStackedNav = site.header[0].layout === "nav-stacked"
  const headerHeight = isStackedNav ? 130 : 80
  const isTransparentNav =
    page?.transparentNav && page?.hideHeader !== "yes" ? true : false

  const transNavFirstSectionStyles = {
    top:
      page?.transparentNav && page?.hideFooter !== "yes"
        ? `-${headerHeight}px`
        : null,
    margin:
      page?.transparentNav && page?.hideFooter === "yes"
        ? `-${headerHeight}px auto 0px auto !important`
        : null,
    left: "0px",
    right: "0px",
    pt: !firstSectionHasParallax ? `${headerHeight}px` : "0px",
  }
  let firstSectionStyling = {
    theme: "default",
    bg: 0,
  }
  let lastSectionStyling = {
    theme: "default",
    bg: 0,
  }

  const visibleSectionsWithStyles = page?.sections?.filter(
    currSection => currSection.section && !currSection.section.hidden
  )
  const firstSection = visibleSectionsWithStyles && visibleSectionsWithStyles[0]
  const lastSection =
    visibleSectionsWithStyles &&
    visibleSectionsWithStyles[visibleSectionsWithStyles.length - 1]

  if (firstSection) {
    firstSectionStyling.theme = firstSection.section.theme
    firstSectionStyling.bg = firstSection.section.bg
    firstSectionStyling.sectionBreak = firstSection.section.sectionBreak
  }
  if (lastSection) {
    lastSectionStyling.theme = lastSection.section.theme
    lastSectionStyling.bg = lastSection.section.bg
    lastSectionStyling.sectionBreak = lastSection.section.sectionBreak
  }

  let prevSection = null
  let visibleSections = page?.sections?.filter(sec => !sec.section?.hidden)

  const footerStyle = {
    marginTop:
      isTransparentNav && visibleSections?.length === 1
        ? `-${headerHeight}px !important`
        : null,
  }

  return (
    <PopupsProvider>
      <Layout
        site={site}
        firstSectionStyling={firstSectionStyling}
        lastSectionStyling={lastSectionStyling}
        isTransparentNav={isTransparentNav}
        bgOpacityNav={page.bgOpacityNav}
        pageSpecificNavTheme={pageSpecificNavTheme}
        footerStyle={footerStyle}
        htmlSnippets={htmlSnippets?.filter(
          snippet => snippet.dataObj?.htmlDetails?.location === "body"
        )}
      >
        <SEO
          socialShare={
            page.socialShareImage
              ? page.socialShareImage
              : site.socialShareImage
          }
          showSiteTitleInPageTitle={site.showSiteTitleInPageTitle}
          siteTitleLocationInPageTitle={site.siteTitleLocationInPageTitle}
          favicon={site.favicon}
          metaDescription={site.siteDescription}
          siteTitle={site.title}
          title={page.metaTitle || page.title}
          keywords={page.keywords}
          pageDescription={page.pageDescription}
          canonicalLink={masterPageURL}
          htmlSnippets={htmlSnippets?.filter(
            snippet => snippet.dataObj?.htmlDetails?.location === "head"
          )}
        />

        {visibleSections?.map((section, i) => {
          const key = `${section.__typename}_${section.id}`
          const lastSectionIndex = visibleSections.length - 1
          prevSection = visibleSections[i - 1]?.section

          const isFirstSection = i === 0
          const isSecondSection = i === 1
          const lastSectionHasBreak =
            section?.section?.sectionBreak === "slope" && i === lastSectionIndex
          const prevSectionHasBreak =
            !isFirstSection &&
            prevSection &&
            prevSection?.sectionBreak === "slope"

          const transNavSectionStyling =
            isFirstSection && isTransparentNav ? transNavFirstSectionStyles : {}

          const sectionPreviewStyling = {
            zIndex: lastSectionIndex - i + 1,
            mt: prevSectionHasBreak
              ? "-81px !important"
              : isTransparentNav && isSecondSection
              ? `-${headerHeight}px !important`
              : null, //hide white space between sections
            pt: prevSectionHasBreak ? `calc(81px + 80px) !important` : null,
            pb: section?.section?.sectionBreak ? `80px` : null,
            mb: lastSectionHasBreak ? "80px !important" : null,
            ...transNavSectionStyling,
          }
          const isFullHeight =
            section?.section?.verticalPadding === "fullHeight"
          const contentStyles =
            isFirstSection && firstSectionHasParallax && isTransparentNav
              ? {
                  pt: `${headerHeight}px`,
                }
              : undefined

          return (
            <Sections
              section={{
                ...section,
                section: {
                  ...section.section,
                  prevSection: prevSection,
                  isFullHeight: isFullHeight,
                  ...sectionPreviewStyling,
                  minHeight: {
                    _:
                      isFullHeight && isTransparentNav
                        ? `calc(100vh - ${headerHeight}px) !important` // needed so that the first section takes on the full height
                        : isFullHeight
                        ? `100vh !important`
                        : null,
                    md:
                      isFullHeight &&
                      isFirstSection &&
                      !isTransparentNav &&
                      page?.hideHeader !== "yes"
                        ? `calc(100vh - ${headerHeight}px) !important`
                        : isFullHeight
                        ? `100vh !important`
                        : null,
                  },
                  contentStyles: contentStyles,
                  navHeight: headerHeight,
                },
              }}
              key={key}
            />
          )
        })}
      </Layout>
    </PopupsProvider>
  )
}

export default Page

export const query = graphql`
  fragment Page on STRAPI_PAGE {
    strapi_id
    title
    slug
    redirectType
    redirectURL
    metaTitle
    masterCopyPageId
    transparentNav
    bgOpacityNav
    overrideSiteStyles
    pageNavTheme
    bgNav
    hideHeader
    hideFooter
    metaTitle
    keywords
    pageDescription
    socialShareImage {
      strapi_id
      url
      alternativeText
      height
      width
      localFile {
        childImageSharp {
          gatsbyImageData(
            formats: [AUTO, WEBP, AVIF]
            layout: CONSTRAINED
            width: 500
          )
        }
      }
    }
    sections {
      ...Sections
    }
    htmlSnippets {
      dataObj: data
      strapi_id
      label
      provider
      providerName
      type
    }
  }
`
// onEnter and preloadQuery functions are meant to improve load time performance
export const preloadQuery = () => {
  return [
    {
      query: query,
      prefetch: true, // This option prefetches the query result before it's actually needed.
    },
  ]
}

export const onEnter = () => {
  // This function tells Gatsby to preload the query as soon as the component is about to enter the viewport
  if (typeof window !== "undefined") {
    window.___gatsbyPrefetchQuery(preloadQuery)
  }
}
